<template>
    <div class="container-2023">
        <let-it-snow
            v-bind="snowConf"
            :show="show"
        />
        <div class="card">
            <h1>
                Merry Christmas
            </h1>
            <h2>
                from Andrew, CJ, Kaya, Delta, & Mavis
            </h2>
            <img
                class="hero-image"
                :src="require('../2023/assets/img/fam.jpg')"
                alt="picture"
            >
            <div class="content">
                <div class="top-header">
                    <strong>What happened in 2023?</strong>
                </div>
                <div class="section-header">
                    <h5>We got engaged!</h5>
                    <hr>
                </div>
                <div class="flex-row">
                    <img
                        class=""
                        :src="require('../2023/assets/img/proposal_2.jpg')"
                        alt="picture"
                    >
                </div>
                <div class="flex-row">
                    <img
                        class="half-width left"
                        :src="require('../2023/assets/img/proposal_1.jpg')"
                        alt="picture"
                    >
                    <img
                        class="half-width lower right"
                        :src="require('../2023/assets/img/proposal_3.jpg')"
                        alt="picture"
                    >
                </div>
                <div class="section-header">
                    <h5>We moved</h5>
                    <hr>
                </div>
                <div class="flex-row">
                    <p class="subtext">
                        We moved to Polk City! Reach out if you need the new address.
                    </p>
                    <img
                        class="half-width left lower"
                        :src="require('../2023/assets/img/new_house.jpg')"
                        alt="picture"
                    >
                    <img
                        class="half-width right"
                        :src="require('../2023/assets/img/office.jpg')"
                        alt="picture"
                    >
                </div>
                <div class="section-header">
                    <h5>We adventured</h5>
                    <hr>
                </div>
                <div class="flex-row">
                    <img
                        class="half-width left lower"
                        :src="require('../2023/assets/img/colorado_1.jpg')"
                        alt="picture"
                    >
                    <img
                        class="half-width lower right"
                        :src="require('../2023/assets/img/colorado_skating.jpg')"
                        alt="picture"
                    >
                </div>
                <div class="flex-row">
                    <img
                        class="half-width upper left"
                        :src="require('../2023/assets/img/cj_colorado.jpg')"
                        alt="picture"
                    >
                    <img
                        class="half-width right"
                        :src="require('../2023/assets/img/us_at_tswift.jpg')"
                        alt="picture"
                    >
                </div>
                <div class="flex-row">
                    <img
                        class=""
                        :src="require('../2023/assets/img/tswift.jpg')"
                        alt="picture"
                    >
                </div>
                <div class="section-header">
                    <h5>& we hung out with friends</h5>
                    <hr>
                </div>
                <div class="flex-row">
                    <img
                        class="half-width left lower"
                        :src="require('../2023/assets/img/boston_2.jpg')"
                        alt="picture"
                    >
                    <img
                        class="half-width right"
                        :src="require('../2023/assets/img/hobbs_2.jpg')"
                        alt="picture"
                    >
                </div>
                <div class="flex-row">
                    <img
                        class="half-width lower"
                        :src="require('../2023/assets/img/new_years_1.jpg')"
                        alt="picture"
                    >
                    <img
                        class="half-width lower left"
                        :src="require('../2023/assets/img/boston_trip.jpg')"
                        alt="picture"
                    >
                </div>
                <div class="flex-row">
                    <img
                        class="half-width lower right"
                        :src="require('../2023/assets/img/cj_eva.jpg')"
                        alt="picture"
                    >
                </div>
                <div class="footer">
                    <strong>Hope you had an amazing year and that 2024 is even better.</strong>
                    <br>
                    <br>
                    <strong>With lots of love - The Gormans</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ChristmasCard2023',
        data() {
            return {
                snowConf: {
                    windPower: 0.25,
                    speed: 0.0005,
                    count: 125,
                    size: 15,
                    opacity: 1,
                    images: [require('../2023/assets/snowflake_1.png'), require('../2023/assets/snowflake_2.png'), require('../2023/assets/snowflake_3.png'), require('../2023/assets/snowflake_4.png')]
                },
                show: false
            };
        },
        mounted() {
            this.show = true;
        }
    };
</script>

<style
    lang="sass"
>
    .container-2023
        align-items: center
        display: flex
        flex-direction: column

        .snow-wrap
            z-index: 1
            top: -2rem
            bottom: -2rem
            right: -2rem
            left: -2rem
            min-height: calc(100vh + 4rem)
            min-width: calc(100vw + 4rem)

            canvas
                position: absolute
                top: -2rem
                bottom: -2rem
                right: -2rem
                left: -2rem
                min-height: calc(100vh + 4rem)
                min-width: calc(100vw + 4rem)

        .card
            z-index: 3
            margin: 5rem 0
            padding: 2rem
            width: 80%
            max-width: 80vw
            background-color: $light
            background-image: url('./assets/fabric_texture.png')
            background-repeat: repeat
            border-radius: 15px
            align-items: center
            display: flex
            flex-direction: column
            justify-content: center

            h1
                font-family: 'Kaushan Script', cursive !important
                color: $red
                font-size: 8vw
                margin: 0
                max-width: 100%

            h2
                font-family: 'Kaushan Script', cursive
                color: $red
                font-size: 2vw
                margin: 0 0 2rem 0
                transform: translateX(60%)

            h5
                color: $red
                font-family: 'Kaushan Script', cursive
                font-size: 2rem

            .subtext
                //font-family: 'Kaushan Script', cursive
                text-align: center
                padding: 0 10rem
                min-width: 20rem
                margin-top: 0
                margin-bottom: 2rem
                font-size: 1.8rem
                color: $red

            .hero-image
                max-height: 50rem
                border: 0.25rem solid $red

            .content
                width: 100%
                font-family: 'Raleway', sans-serif

                .top-header
                    font-family: 'Kaushan Script', cursive
                    padding-top: 2rem
                    font-size: 3rem
                    text-align: center
                    width: 100%
                    color: $red

                .section-header
                    display: flex
                    flex-direction: row
                    justify-content: space-between
                    align-items: center
                    width: 100%

                    h5
                        margin-left: 2rem
                        margin-right: 2rem

                    hr
                        border: 2px solid $red
                        flex-grow: 1
                        margin: 0
                        border-radius: .25rem

            .flex-row
                display: flex
                flex-direction: row
                justify-content: space-evenly
                align-items: flex-start
                max-width: 100%
                padding: 1rem
                flex-wrap: wrap

                img
                    border: 10px solid white
                    box-shadow: 15px 15px 20px 1px #1d1d1d63
                    aspect-ratio: initial
                    object-fit: contain
                    height: auto
                    width: auto

                    &.left
                        transform: rotatez(-5deg)

                    &.right
                        transform: rotatez(5deg)

                    &.lower
                        margin-top: 4rem

                .half-width
                    max-width: 40%
                    min-width: 15rem
                    margin: 1rem

                .third-width
                    max-width: 20vw

            .footer
                font-family: 'Kaushan Script', cursive
                font-size: 2rem
                text-align: center
                padding: 2rem 10rem
                color: $red

                img
                    margin: 2rem 0

            .post-script
                margin-top: 3rem


        @media(max-width: 800px)
            .card
                h1
                    font-size: 2rem

                h2
                    transform: none
                    font-size: 1.5rem

                .content
                    .top-header
                        font-size: 2rem
                        margin-bottom: 2rem

                    .section-header
                        flex-direction: column
                        justify-content: center
                        align-items: center
                        text-align: center
                        margin-top: 4rem

                        h5
                            text-wrap: avoid
                            width: 100%
                            padding: 0
                            margin: 0 0 1rem 0

                        hr
                            width: 60%

                .footer
                    font-size: 2rem
                    text-align: left
                    padding: 0.25rem

                .post-script
                    hr
                        display: none


</style>
